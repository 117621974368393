



















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Contact extends Vue {
    private isLoading = false;
    private formUrl: string = '';
    private mounted() {
        this.formUrl = `${process.env.VUE_APP_CONTACT_FORM_URL}`;
        (this.$refs.form as Element).addEventListener('load', () => {
            this.isLoading = true;
        });
    }
}
