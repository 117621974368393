
































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
    public loginUrl = `${process.env.VUE_APP_LOGIN_URL}`;
    public name = 'App';

    public clickLogo() {
        this.$router.push('/', () => {
            // for lint comment
        });
    }
}
