




















































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Home extends Vue {
    private previewHeight: number = 0;
    private showCatchCopy = false;
    private showLine1 = false;
    private showLine2 = false;

    private resize() {
        this.previewHeight = window.innerHeight * 0.8;
    }

    private mounted() {
        this.resize();
        window.addEventListener('resize', this.resize);
        setTimeout(() => {
            this.showCatchCopy = true;
        }, 500);
        setTimeout(() => {
            this.showLine1 = true;
        }, 1500);
        setTimeout(() => {
            this.showLine2 = true;
        }, 2500);
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    }
}
